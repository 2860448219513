@import "/src/assets/styles/mixins.scss";

.currency-price-card{
  border-radius: 17px;
  border: 1px solid #D1D1D1;
  width: 189px;
  overflow: hidden;
  .currency-price-card-header{
    background-color: $light-steel-gray;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    column-gap: 5px;
    font-size: 12px;
    font-weight: 700;
    color: $graphite-grey;
    span.emoji{
      font-size: 25px;
    }
    img{
      width: 27px;
      height: 27px;
      object-fit: contain;
    }
  }
  
  .currency-price-card-content{
    padding: 15px;
    min-height: 70px;
    input{
      font-size: 21px;
      font-weight: 700;
      color: $deep-blue;
      background-color: transparent;
      border: none;
      outline: 0;
      box-shadow:none;
      padding: 0;
      &[type="number"]::-webkit-inner-spin-button,
      &[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
      }
    }
    .rate{
      font-size: 10px;
      color: $deep-blue;
      position: absolute;
    }
  }
}
