@import '/src/assets/styles/index.scss';

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 20;
  background-color: $gray-mine-shaft;

  &__container {
    position: relative;
    border: 0.5px solid $gray-mine-shaft;
    height: inherit;
    padding: 20px 0 20px 0;
    margin: 0 auto;
    max-width: $normalDesktop;

    @include breakpoint-max($normalDesktop) {
      max-width: $smallDesktop;
      padding: 10px 0;
    }

    @include breakpoint-max($smallDesktop) {
      max-width: $desktop;
    }

    @include breakpoint-max($tablet) {
      display: flex;
      justify-content: center;
      align-items: center;
      background: $gray-mine-shaft;
      padding: 20px 0 20px 0;
    }
  }

  &__logo {
    width: 300px;
    height: 100%;
    object-fit: cover;
    margin-left: 155px;

    @include breakpoint-max($normalDesktop) {
      margin-left: 0;
    }

    @include breakpoint-max($tablet) {
      margin-left: 0;
      width: 250px;
    }


  }

  &__halftone {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%;

    @include breakpoint-max($tablet) {
      display: none;
    }


    @include breakpoint-max($smallDesktop - 1px) {
      display: none;
    }
  }
}