@import '/src/assets/styles/mixins';

.medicare {
  margin-top: 10px;
  height: fit-content;
  border: 1px solid $silver-cloud;
  border-radius: 13px 13px 16px 16px;
  transition: all 0.25s linear;
}

.medicare__form {
  padding: 24px 18px;
  padding-top: 4px;

  @include breakpoint-max($largeDesktop - 1px) {
    flex-wrap: wrap;
  }

  &--content {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 25px;

    @include breakpoint-max($tablet) {
      flex-wrap: wrap;
    }

  }

  &--group {
    position: relative;
    flex: 1;

    @include breakpoint-max($tablet) {
      min-width: fit-content;
      flex: unset;
    }
  }


  &--label {
    font-size: 12px;
    font-weight: 500;
    color: $nevada-color;
    letter-spacing: 1.5px;
    margin-bottom: 6px;
    white-space: nowrap;
  }

  &--input {
    padding: 12px 20px;
    border-radius: 6px;
    border: 0.625px solid $slate-blue-gray;
    font-size: 12px;
    font-weight: 400;
    color: $black-color;
    background-color: $light-gray;
    height: 40px;

    &:focus {
      outline: none;
    }
  }

  &--input--reference--number {
    width: 52px;
    height: 41px;
    @include hiddenArrowInputNumber();
  }

  &--input--error {
    border-color: $salmon-red;
  }

  &--input--number {
    width: 144px;
    @include hiddenArrowInputNumber()
  }

  &--input--date {
    text-transform: uppercase;
    width: 144px;
    @include hiddenCalendarInput();
  }

  &--group--medicare--number {
    position: relative;
    width: fit-content;
  }

  &--error {
    font-size: 8px;
    font-weight: 500;
    color: $salmon-red;
    margin-bottom: 0;
    text-align: end;
    position: absolute;
    bottom: -16px;
    right: 0;

    &--number,
    &--date {
      width: 144px;
    }

    &--reference {
      flex: 1;
    }
  }

  &--errors {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 25px;
    margin-top: 8px;
  }
}


.medicare__country {
  &--list {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    height: 156px;
    width: 200%;
    overflow: auto;
    background-color: $white-color;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    z-index: 2;
    padding: 14px 0;
    border-radius: 6px;
  }

  &--list--empty {
    height: fit-content;
    padding: 14px;
  }

  &--item {
    padding: 14px 16px;
    cursor: pointer;

    &:hover {
      background-color: rgba($color: $silver-color, $alpha: 0.38);
    }
  }

  &--empty {
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    color: $silver-color;
  }
}