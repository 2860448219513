@import "/src/assets/styles/mixins.scss";

.started-form-wrap {
  padding: 30px 0 30px 155px;
  margin: 0 auto;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-family: "Poppins", sans-serif;


  @include breakpoint-max($normalDesktop) {
    padding-left: 0;
    padding-top: 10px;
  }

  @include breakpoint-max($smallDesktop - 1px) {
    align-items: center;
  }

  @include breakpoint-max($tablet) {
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 0;
    justify-content: center;
    align-items: flex-start;
  }

  .started-form-header {
    color: $deep-blue;
    margin-bottom: 40px;


    @include breakpoint-max($normalDesktop) {
      margin-bottom: 20px;
    }

    @include breakpoint-max($tablet) {
      margin-bottom: 20px;
    }
  }

  .started-form {
    width: 392px;

    @include breakpoint-max($tablet) {
      width: 100%;
    }
  }

  .registration-section {
    margin-top: auto;
    margin-top: 100px;

    @include breakpoint-max($normalDesktop) {
      margin-top: 36px;
    }

    @include breakpoint-max($tablet) {
      margin-top: 30px;
    }

    .registration-terms {
      font-size: 10px;
      font-weight: 500;
      margin-top: 15px;
      text-align: center;
      color: $deep-blue;

      a {
        font-size: 10px;
        font-weight: 700;
        color: $deep-blue;
        text-decoration: underline;
      }
    }
  }
}

.started-form {
  font-family: "Poppins", sans-serif;
}

.started-form__label {
  font-size: 12.52px;
  font-weight: 500;
  letter-spacing: 1px;
  color: $medium-gray;
}

.started-form__group {
  position: relative;

  &:not(:first-child) {
    margin-top: 20px;

    @include breakpoint-max($normalDesktop) {
      margin-top: 10px;
    }

    @include breakpoint-max($tablet) {
      margin-bottom: 10px;
    }
  }
}

.started-form__input {
  padding: 12px 16px;
  font-size: 12px;
  font-weight: 400;
  color: $black-color;
  border: 0.7px solid $slate-blue-gray;
  border-radius: 6px;
  width: 100%;
  background-color: $light-gray !important;

  &--error {
    border-color: $vivid-red;
  }

  &:hover {
    background-color: $light-gray;
    outline: none;
  }

  &:focus {
    outline: none;
    background-color: $light-gray;
    box-shadow: none;
  }
}

.started-form__error--message {
  position: absolute;
  right: 0;
  bottom: -20px;
  text-align: end;
  display: block;
  @include errorMessage;
}