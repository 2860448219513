@import "/src/assets/styles/mixins.scss";

.currency-card {
  background: $light-gray;
  border-radius: 8px;
  display: flex;
  align-items: center;
  width: 157px;
  padding: 8px 12px;
  column-gap: 6px;
  cursor: pointer;

  &.currency-card-active {
    background: $sky-frost 0% 0% no-repeat padding-box;
  }

  &__emoji {
    width: 38px;
    height: 38px;
    object-fit: cover;
  }

  .currency-card-content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    h5 {
      font-size: 14px;
      font-weight: 700;
      margin-bottom: 0;
      color: $deep-blue;
      width: 100%;
      margin-top: 0;
    }

    span {
      font-size: 8px;
      font-weight: 500;
      color: $deep-blue;
    }
  }

}