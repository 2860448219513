// fonts
@font-face {
  font-family: "Poppins";
  font-weight: 400;
  font-style: normal;
  src: url("./fonts/Poppins/Poppins-Regular.ttf") format('truetype');
}

@font-face {
  font-family: "Poppins";
  font-weight: 500;
  font-style: normal;
  src: url("./fonts/Poppins/Poppins-Medium.ttf") format('truetype');
}

@font-face {
  font-family: "Poppins";
  font-weight: 600;
  font-style: normal;
  src: url("./fonts/Poppins/Poppins-SemiBold.ttf") format('truetype');
}

@font-face {
  font-family: "Poppins";
  font-weight: 700;
  font-style: normal;
  src: url("./fonts/Poppins/Poppins-Bold.ttf") format('truetype');
}

@font-face {
  font-family: "Poppins";
  font-weight: 800;
  font-style: normal;
  src: url("./fonts/Poppins/Poppins-ExtraBold.ttf") format('truetype');
}


@font-face {
  font-family: "AvenirNextCondensed";
  font-weight: 600;
  font-style: normal;
  src: url("./fonts/Avernir-Next-Condensed/AvenirNextCondensed-DemiBoldItalic.ttf") format('truetype');
}


$largeDesktop: 1920px;
$normalDesktop: 1477px;
$smallDesktop: 1024px;
$desktop: 900px;
$tablet: 768px;
$mobile: 600px;
$heightSmallDesktop: 1300px;

// colors
$light-gray: #E7E9EC;
$deep-blue: #30375E;
$medium-gray: #696d77;
$slate-blue-gray: #818794;
$silver-cloud: #C5C5C7;
$frosty-sky: #D4F7FF;
$silver-gray: #D1D1D1;
$light-steel-gray: #DBDBDB;
$silver-white: #F2F2F2;
$bright-sky-blue: #00C8FF;
$bright-lime: #D8FF32;
$stone-gray: #A0A0A0;
$sky-frost: #AAEAF9;
$midnight-black: #111111;
$graphite-grey: #353535;
$vivid-red: #EF1111;
$salmon-red: #E04B4B;
$black-color: #000;
$white-color: #fff;
$blue-color: #2B3480;
$silver-color: #BEBEBE;
$nevada-color: #696D77;
$gallery-color: #EFEFEF;
$limeade-color: #33A000;
$mine-shaft-color: #3C3C3C;
$razzmatazz-color: #D90F58;
$silver-chalice: #A5A5A5;
$blue-bright-turquoise: #00C9FF;
$blue-rock: #9BB3CE;
$gray-gallery: #EAEAEA;
$gray-mine-shaft: #2B2B2B;
$golden-fizz: #D6FF32;
$gray-color: #898989;
$pink-carnation: #FFA2C7;