@import "/src/assets/styles/mixins.scss";

.button-next-step {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;
  font-size: 12.71px;
  font-weight: 700;
  color: $white-color;
  width: 245px;
  height: 42px;
  border-radius: 100rem;
  background-color: $blue-color;
  border: none;
  margin: 0 auto;

  &--disabled {
    opacity: 0.8;
  }

  &__icon {
    width: 8px;
    height: 9px;
  }
}