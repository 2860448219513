@import '/src/assets/styles/mixins';

.loading {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 9999;
  height: 100%;
  width: 100%;
  background-color: rgba($color: $mine-shaft-color, $alpha: 0.74);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  flex-direction: column;
}

.loading__container {
  position: relative;
}

.loading__logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 33px;
  height: 44px;
}

.loading__loader {
  width: 94px;
  aspect-ratio: 1;
  border-radius: 50%;
  background:
    radial-gradient(farthest-side, $bright-lime 94%, #0000) top/6px 6px no-repeat,
    conic-gradient(#0000 30%, $bright-lime);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 6px), #000 0);
  animation: l13 1s infinite linear;
}

.loading__text {
  font-size: 21px;
  font-weight: 600;
  color: $golden-fizz;
  margin-top: 25px;
  font-family: 'AvenirNextCondensed', sans-serif;
}

@keyframes l13 {
  100% {
    transform: rotate(1turn)
  }
}