@import '/src/assets/styles/mixins';

.form-edit {
  &__group {
    position: relative;

    &:not(:first-child) {
      margin-top: 20px;
    }
  }

  &__input {
    width: 100%;
    padding: 12px 20px;

    border: 0.67px solid $slate-blue-gray;
    background-color: $light-gray;
    font-size: 13px;
    font-weight: 500;
    color: $black-color;
    border-radius: 6px;

    &:focus {
      outline: none;
    }
  }

  &__label {
    display: block;
    font-size: 12px;
    font-weight: 500;

    color: $nevada-color;
    letter-spacing: 1.5px;
    margin-bottom: 6px;
  }


  &__error--message {
    font-size: 10px;
    font-weight: 500;
    color: $vivid-red;
    margin-top: 8px;
  }

  &__input--date {
    &::-webkit-inner-spin-button,
    &::-webkit-calendar-picker-indicator {
      display: none;
      -webkit-appearance: none;
    }
  }

}

.form-edit__btn {
  &--list {
    margin-top: 44px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 24px;

    @include breakpoint-max($tablet) {
      gap: 14px;
      margin-top: 30px;
    }
  }

  &--item {
    flex: 1;
    border-radius: 100rem;
    font-size: 12.71px;
    font-weight: 700;

    display: flex;
    justify-content: center;
    align-items: center;

    border: none;
    padding: 13px 34px;

    @include breakpoint-max($tablet) {
      font-size: 10px;
    }
  }

  &--item--save {
    background-color: $limeade-color;
    color: $white-color;

    &:active {
      opacity: 0.8;
    }
  }

  &--item--cancel {
    background-color: $light-gray;
    color: $deep-blue;
  }
}

.form-edit__country {
  &--list {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    height: 156px;
    overflow: auto;
    background-color: $white-color;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    z-index: 2;
    padding: 14px 0;
  }

  &--item {
    padding: 14px 16px;

    &:hover {
      background-color: rgba($color: $silver-color, $alpha: 0.38);
    }
  }


  &--list--empty {
    height: fit-content;
    padding: 24px;
  }

  &--empty {
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    color: $silver-color;
  }
}


.form-edit__group--address {
  & .form-edit__select-address__control {
    font-size: 14px;
    font-weight: 400;
    color: $black-color;
    background-color: $light-gray;
    border: 0.67px solid $slate-blue-gray;
    border-radius: 6px;
    width: 100%;
    padding: 0;

    &:focus {
      outline: none;
    }
  }

  & .form-edit__select-address__control--is-focused {
    border-color: $slate-blue-gray !important;
    box-shadow: none;
  }

  & .form-edit__select-address__value-container {
    padding: 12px 20px;
    width: 100px;
  }

  & .form-edit__select-address__input-container {
    padding: 0;
    margin: 0;
  }

  & .form-edit__select-address__indicators {
    display: none;
  }

  & .form-edit__select-address__option--is-focused {
    background-color: rgba($color: $silver-color, $alpha: 0.38);
  }

  & .form-edit__select-address__option {
    cursor: pointer;
  }

  & .form-edit__select-address__option--is-focused {
    background-color: rgba($color: $silver-color, $alpha: 0.38);
  }

  & .form-edit__select-address__input {
    opacity: 1 !important;
    width: 180%;
  }

  & .form-edit__select-address__menu-notice--no-options {
    display: none;
  }

  & .form-edit__select-address__menu-list {
    min-height: 55px;
  }

  & .form-edit__select--menu {
    &.hidden {
      display: none;
    }
  }
}