@import "/src/assets/styles/mixins.scss";

.button-back-step {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
  padding: 0;

  &__text {
    color: #30375e;
    text-decoration: underline;
    font-size: 16px;
    font-weight: 600;

    @include breakpoint-max($normalDesktop) {
      font-size: 12px;
    }
  }
}