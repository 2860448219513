@import "/src/assets/styles/mixins.scss";

.background-form {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 33px 20px;
  @include breakpoint-max($smallDesktop - 1px) {
    // height: 600px;
  }
}