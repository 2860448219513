@import "/src/assets/styles/index.scss";

.last-screen {
  width: 100%;

  &__title--success {
    font-size: 16px;
    font-weight: 500;
    color: $deep-blue;
    margin: 37px 0;
    text-align: center;

    span {
      font-weight: 700;
    }
  }
}

.steps-container-pending {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  margin-bottom: 60px;
  @include breakpoint-max($normalDesktop + 1px) {
    grid-template-columns: repeat(5, 1fr);
  }

  @include breakpoint-min-max($smallDesktop, $normalDesktop) {
    margin-bottom: 40px;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }

  @include breakpoint-max($normalDesktop) {
    gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  }

  @include breakpoint-max($desktop) {
    grid-template-columns: repeat(2, 1fr);
    padding: 0 14px;
  }

  @include breakpoint-max($tablet) {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }

  @include breakpoint-max($mobile) {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }

  .step_5 {
    border: 1px solid #ccc;
    border-radius: 10px;
    text-align: center;
    display: flex;
    flex-direction: column;
    padding: 20px;
    padding-bottom: 0;

    .step-number {
      background-color: #00b0ff;
      color: white;
      font-size: 24px;
      width: 42px;
      height: 42px;
      line-height: 40px;
      border-radius: 50%;
      margin: 0 auto;
      font-weight: bold;
    }

    h3 {
      font-weight: bold;
      font-size: 20px;
      margin-top: 10px;
      margin-bottom: 0;
      @include breakpoint-max($normalDesktop) {
        font-size: 16px;
      }
    }

    .step-content {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      height: 100%;
      width: 100%;

      &__image-4 {
        width: 153px;
        height: 90px;
        object-fit: cover;
        margin-top: 10px;

      }
    }
  }

  .step {
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    .step-number {
      background-color: #00b0ff;
      color: white;
      font-size: 24px;
      width: 42px;
      height: 42px;
      line-height: 40px;
      border-radius: 50%;
      margin: 0 auto;
      font-weight: bold;
    }

    &__title {
      @extend .last-screen__title--success;
      font-size: 9px;
      margin: 0;
      display: block;
      margin-bottom: 10px;
      margin-top: 0;

      @include breakpoint-max($normalDesktop) {
        font-size: 8px;
        margin-bottom: 8px;
      }
    }

    &__title-tpm {
      @extend .last-screen__title--success;
      font-size: 10px;
      margin: 0;
      display: block;
      font-weight: bold;
      color: $black-color;

    }

    h3 {
      font-weight: bold;
      font-size: 20px;
      margin-top: 10px;
      margin-bottom: 0;
      @include breakpoint-max($normalDesktop) {
        font-size: 16px;
      }
    }

    .step-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;

      &__image-4 {
        width: 153px;
        height: 90px;
        object-fit: cover;
        margin-top: 10px;
      }

      &__image-complete {
        width: 115px;
        height: 53px;
        object-fit: contain;
        margin-top: 18px;

        @include breakpoint-max($smallDesktop) {
          height: 80px;
        }

        @include breakpoint-max($desktop) {
          height: 80px;
        }

        @include breakpoint-max($tablet) {
          height: 80px;
        }

        @include breakpoint-max($mobile) {
          height: 80px;
        }
      }

      .app-store-buttons {
        display: flex;
        margin-top: 24px;

        img {
          margin: 0 5px;
          width: auto;
          height: 60px;
          object-fit: cover;

          @include breakpoint-max($normalDesktop + 1px) {
            width: 120px;
            height: auto;
          }

          @include breakpoint-min-max($smallDesktop, $normalDesktop) {
            width: 140px;
            height: auto;
          }

          @include breakpoint-max($smallDesktop) {
            width: 15rem;
            height: auto;
            object-fit: contain;
          }

          @include breakpoint-max($desktop) {
            width: 200px;
            height: auto;
            object-fit: contain;
          }

          @include breakpoint-max($tablet) {
            width: 25rem;
            height: auto;
            object-fit: contain;
          }

          @include breakpoint-max($mobile) {
            width: 20rem;
            height: auto;
            object-fit: contain;
          }
        }
      }

      .tpm-code {
        background-color: #d1114e;
        color: white;
        padding: 6px 25px;
        border-radius: 12px;
        font-size: 18px;
        font-weight: bold;
        margin-top: 15px;
        width: 135px;
        border: 1px solid $pink-carnation;


        @include breakpoint-max($smallDesktop) {
          width: 150px;
        }

        @include breakpoint-max($desktop) {
          width: 200px;
        }

        @include breakpoint-max($tablet) {
          width: 200px;
        }

        @include breakpoint-max($mobile) {
          width: 150px;
        }
      }
    }

    .email-input-container {
      display: flex;
      align-items: center;
      justify-content: center;

      .email-view {
        flex-direction: row;
        display: flex;
        align-items: center;

        @include breakpoint-max($normalDesktop) {
          width: 100%;
        }

        @include breakpoint-max($smallDesktop) {
          width: 90%;
        }

        @include breakpoint-max($desktop) {
          width: 100%;
        }

        @include breakpoint-max($tablet) {
          width: 100%;
        }

        @include breakpoint-max($mobile) {
          width: 80%;
        }
      }

      .email-icon {
        width: 100%;
        height: 40px;
        object-fit: contain;
      }

      .email-text {
        flex-grow: 1;
        font-size: 16px;
        color: #333;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; // Prevents text from wrapping
      }
    }
  }
}