@import "/src/assets/styles/index.scss";

.last-screen {
  width: 100%;

  &__banner {
    width: 100%;
    object-fit: cover;
  }

  &__title--success {
    font-size: 16px;
    font-weight: 500;
    color: $deep-blue;
    margin: 37px 0;
    text-align: center;

    span {
      font-weight: 700;
      color: $deep-blue;
    }
  }

  &__title--pending {
    @extend .last-screen__title--success;
  }

  &__title--fail {
    @extend .last-screen__title--success;

    span {
      font-weight: 500;
      display: block;
      margin-top: 24px;
      color: $deep-blue;
    }

    &--mail {
      font-weight: 700;
      color: $deep-blue;
    }
  }
}