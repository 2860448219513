@import '/src/assets/styles/mixins';

.modal-edit {
  &__title {
    font-size: 28px;
    font-weight: 700;
    color: $deep-blue;
    margin-bottom: 0;
  }

  &__subtitle {
    font-size: 12px;
    font-weight: 500;
    color: $deep-blue;
    margin-bottom: 40px;
  }
}

.ant-modal-content.modal-edit__content {
  border-radius: 16px;
  padding: 45px 40px;
  font-family: "Poppins", sans-serif;
  @include breakpoint-max($tablet){
    padding: 20px;
  }
}