@import '/src/assets/styles/mixins';

.onboarding__content {
  margin: 0 auto;
  max-width: $normalDesktop;

  @include breakpoint-max($normalDesktop) {
    max-width: $smallDesktop;
  }

  @include breakpoint-max($smallDesktop) {
    max-width: $desktop;
  }
}

.onboarding-body {
  margin-top: 70px;

  @include breakpoint-max($normalDesktop) {
    margin-top: 50px;
  }
  @include breakpoint-max($tablet) {
    margin-top: 65px;
  }

  .onboarding__form--section {
    height: 739px;
    // height: 100%;

    @include breakpoint-max($largeDesktop - 1px) {
      height: 540px;
    }

    @include breakpoint-max($tablet) {
      height: 400px;
    }
  }



  .onboarding__banner--right {
    width: 100%;
    height: 100%;
    object-fit: contain;

    // @include breakpoint-height-max($heightSmallDesktop) {
    //   width: 80%;
    // }

    @include breakpoint-max($normalDesktop) {
      height: 100%;
    }

    @include breakpoint-max($smallDesktop - 1px) {
      height: 100%;
    }
  }

  .get-started-card-image {
    width: 327px;
    height: 544px;

    @include breakpoint-max($tablet) {
      width: 250px;
      height: 420px;
    }
  }
}