@import '/src/assets/styles/mixins';

.identity-placeholder {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 9px 17px 9px 9px;
  cursor: pointer;
  border: 1px solid $frosty-sky;
  background-color: rgba($color: $silver-cloud, $alpha: 0.3);
  border-radius: 13px;
  width: 100%;
  &__open {
    background-color: $sky-frost;
  }

  &__content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 14px;
  }

  &__img {
    width: 42px;
    height: 42px;
    object-fit: cover;
  }

  &__title {
    font-size: 14px;
    font-weight: 600;
    color: $blue-color;
    margin-bottom: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
  }

  &__icon {
    width: 18px;
    height: 18px;
    object-fit: cover;
  }

  &__icon--arrow {
    width: 10px;
    height: 6px;
    object-fit: cover;
  }
}