@import "/src/assets/styles/mixins.scss";

.travel-money-wrap {
  max-width: 100%;
  margin-left: auto;
  padding: 30px 40px 30px 155px;
  display: flex;
  flex-direction: column;
  height: 100%;

  @include breakpoint-max($tablet) {
    padding-left: 5%;
    padding-right: 5%;
    justify-content: center;
    align-items: flex-start;
  }

  @include breakpoint-max($tablet) {
    height: auto;
  }

  .header-form-view {
    margin: 40px 0;
  }

  .travel-money-currency-card {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
}

.travel-money-select {
  margin-top: 20px;
  font-family: "Poppins", sans-serif;

  .ant-select-selector {
    border-color: $deep-blue !important;
    border-radius: 8px;

    .ant-select-selection-item {
      color: $deep-blue;
      font-weight: 700;
      padding-right: 0;
    }
  }

  .ant-select-arrow {
    color: $deep-blue;
  }

}

.travel-money-body {
  margin-bottom: 10px;
  height: 100%;

  .validation-info {
    margin-top: 30px;
    color: #7c7a7a;
  }

  .travel-money-more-currencies {
    height: 80%;
    width: 90%;
    display: flex;
    flex-direction: column;

    .travel-money-exchange {
      margin-top: 30px;
      margin-bottom: 40px;
      width: 100%;

      .travel-money-exchange-item {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        row-gap: 12px;

        @include breakpoint-max($tablet) {
          flex-direction: column;
        }

        &:not(:last-child) {
          margin-bottom: 20px;

          @include breakpoint-max($tablet) {
            margin-bottom: 40px;
          }
        }

        .close {
          color: $midnight-black;
          background: $silver-cloud;
          width: 25px;
          height: 25px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          font-size: 15px;
          cursor: pointer;

          @include breakpoint-max($tablet) {
            position: absolute;
            top: -20px;
            right: 20px;
          }
        }
      }

      .travel-money-exchange-item__equal {
        @include breakpoint-max($tablet) {
          font-size: 25px;
          font-weight: 600;
        }
      }
    }

    .travel-money-button {
      max-width: 85%;
    }
  }

}

.travel-money-option {
  .ant-select-item-option-content {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;

    .label {
      width: auto;
      display: flex;
      align-items: center;
      column-gap: 5px;
      color: $black-color;

      .emoji {
        font-size: 20px;
      }
    }

    .short-name {
      width: auto;
      color: $stone-gray;
    }
  }

}

.registration-submit-button {
  background-color: #2B3480 !important;
}

.travel-money-exchange-item-error {
  .currency-price-result-card {
    border-color: $vivid-red;
  }
}

.currency-price-result-msg-error {
  font-size: 12px;
  color: #E04B4B;
  margin-top: -10px;
  text-align: right;
  position: relative;
  width: 100%;

  @include breakpoint-max($tablet) {
    text-align: center;
  }
}

.travel-money__select--label {
  font-size: 12px;
  font-weight: 700;
  color: $deep-blue;
  font-family: "Poppins", sans-serif;
}

.travel-money__select--icon {
  width: 9px;
  height: 5px;
}

.rc-virtual-list-holder{
  padding-right: 16px;
}