@import "/src/assets/styles/mixins.scss";

.steps-boarding {
  padding: 24px 0 40px 0;
  background-color: $silver-white;
  text-align: center;

  @include breakpoint-max($normalDesktop) {
    padding: 14px 0 24px 0;
  }

  @include breakpoint-max($tablet - 1px) {
    padding: 20px 0 30px 0;
  }

  &__container {
    max-width: $normalDesktop;
    margin: 0 auto;

    @include breakpoint-max($normalDesktop) {
      max-width: $desktop;
    }
  }

  .steps-boarding-title {
    background-color: $bright-sky-blue;
    display: inline-block;
    font-size: 31px;
    font-weight: 700;
    color: $white-color;
    margin-bottom: 0;
    margin-bottom: 24px;
    letter-spacing: 1px;
    padding: 0 6px;
    @include breakpoint-max($normalDesktop) {
      margin-bottom: 0;
      font-size: 26px;
    }
    span {
      color: $bright-lime;
    }

    @include breakpoint-max($tablet) {
      font-size: 25px;
    }
  }

  .steps-wrap {
    width: 40%;
    max-width: 596px;
    margin: 0 auto;
    white-space: nowrap;

    @include breakpoint-max($tablet) {
      width: 90%;
    }

    .ant-steps-item-icon {
      display: none !important;
    }

    .ant-steps-item-tail {
      top: 48px;
      opacity: 0.5;

      &:after {
        background-color: transparent;
        background: linear-gradient(90deg, $deep-blue 50%, transparent 50%);
        background-size: 15px 100%;
      }
    }

    .steps-custom-title {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: center;
      opacity: 0.5;
      font-family: "Poppins";
    }

    span {
      width: 100%;
      color: $deep-blue;
    }

    .ant-steps-item-active,
    .ant-steps-item-finish {
      .steps-custom-title {
        font-weight: 700;
        opacity: 1;
      }

    }

    .ant-steps-item-finish {
      .ant-steps-item-tail {
        opacity: 1;

        &:after {
          background-color: transparent;
        }
      }
    }
  }

}

.steps-onboarding__icon {
  width: 30px;

  @include breakpoint-max($normalDesktop){
    width: 20px;
  }
  @include breakpoint-max($tablet - 1px) {
    width: 25px;
  }
}