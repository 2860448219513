@import "/src/assets/styles/variables.scss";

$breakpoints: (
  mobile: 430px,
  tablet: 768px,
  desktop: 1200px) !default;

$breakpoint-mobile: map-get($breakpoints, "mobile"
);
$breakpoint-tablet: map-get($breakpoints, "tablet");
$breakpoint-desktop: map-get($breakpoints, "desktop");

@mixin breakpoint-min($bp) {
  @media (min-width: $bp) {
    @content;
  }
}

@mixin breakpoint-max($bp) {
  @media (max-width: $bp) {
    @content;
  }
}

@mixin breakpoint-min-max($bp1, $bp2) {
  @media (min-width: $bp1) and (max-width: $bp2) {
    @content;
  }
}

@mixin for-mobile-only {
  @include breakpoint-max($breakpoint-mobile) {
    @content;
  }
}

@mixin for-mobile-up {
  @include breakpoint-min($breakpoint-mobile) {
    @content;
  }
}

@mixin for-tablet-only {
  @include breakpoint-min-max($breakpoint-tablet, $breakpoint-desktop) {
    @content;
  }
}

@mixin for-tablet-up {
  @include breakpoint-min($breakpoint-tablet) {
    @content;
  }
}

@mixin for-tablet-down {
  @include breakpoint-max($breakpoint-tablet) {
    @content;
  }
}

@mixin for-desktop-only {
  @include breakpoint-min-max($breakpoint-desktop, $breakpoint-desktop) {
    @content;
  }
}

@mixin for-desktop-up {
  @include breakpoint-min($breakpoint-desktop) {
    @content;
  }
}

@mixin for-desktop-down {
  @include breakpoint-max($breakpoint-desktop) {
    @content;
  }
}


@mixin breakpoint-height-min($bp) {
  @media (min-height: $bp) {
    @content;
  }
}

@mixin breakpoint-height-max($bp) {
  @media (max-height: $bp) {
    @content;
  }
}


@mixin hiddenCalendarInput {

  &::-webkit-inner-spin-button,
  &::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }
}

@mixin hiddenArrowInputNumber {

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
}

@mixin errorMessage {
  font-size: 10px;
  font-weight: 500;
  color: $vivid-red;
}

@mixin hiddenScrollbar {

  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */

  &::-webkit-scrollbar {
    display: none;
    background: transparent;
    width: 0px;
    /* Safari and Chrome */
  }
}