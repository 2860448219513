@import 'src/assets/styles/mixins';

.verify-identity {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 28px 0;
  padding-left: 155px;
  height: 100%;

  @include breakpoint-min-max($tablet, $normalDesktop) {
    padding-left: 0;
  }

  @include breakpoint-max($smallDesktop - 1px) {
    justify-content: center;
  }

  @include breakpoint-max($tablet) {
    padding-left: 5%;
    padding-right: 5%;
    justify-content: center;
    align-items: flex-start;
  }

  &__container {
    width: 480px;
    height: 100%;

    @include breakpoint-max($tablet) {
      width: 100%;
    }
  }

  &__btn--back {
    margin-bottom: 20px;
  }

  &__error {
    font-size: 10px;
    font-weight: 500;
    color: $salmon-red;
    margin-top: 10px;
    margin-bottom: 0;
    text-align: center;
  }

  &__form--content {
    width: 100%;
  }

  &__form--action {
    margin-top: 29px;

    @include breakpoint-max($tablet - 1px) {
      margin-top: 10px;
    }
  }
}

.verify-identity__form {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: calc(100% - 200px);
  flex-direction: column;
  margin-top: 20px;
}


.verify-identity__form--btn--submit {
  background-color: $limeade-color;
  color: $white-color;
  margin-top: 20px;
  margin-bottom: 6px;

  &:active {
    outline: none;
  }
}

.verify-identity {
  &__agree--policy {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
  }

  &__agree--policy--text {
    font-size: 10px;
    font-weight: 500;
    color: $deep-blue;

    @include breakpoint-max($tablet - 1px) {
      font-size: 8px;
    }
  }

  & .verify-identity__agree--policy--checkbox-checked.verify-identity__agree--policy--checkbox-indeterminate .verify-identity__agree--policy--checkbox-inner:after {
    background-color: $limeade-color;
  }

  & .verify-identity__agree--policy--checkbox .verify-identity__agree--policy--checkbox-input:focus-visible+.verify-identity__agree--policy--checkbox-inner {
    outline: none;
  }

  & .verify-identity__agree--policy--checkbox-indeterminate .verify-identity__agree--policy--checkbox-inner:after {
    background-color: transparent;
    border-radius: 2px;
    width: 7px;
    height: 7px;
  }

  & .verify-identity__agree--policy--checkbox .verify-identity__agree--policy--checkbox-inner {
    border: 2px solid $deep-blue;
    width: 14px;
    height: 14px;
    border-radius: 4px;

    &:hover {
      border-color: $deep-blue;
    }
  }

  .verify-identity__agree--policy--checkbox-checked:not(.verify-identity__agree--policy--checkbox-disabled):hover .verify-identity__agree--policy--checkbox-inner {
    background-color: $white-color;
  }

  .verify-identity__agree--policy--checkbox-wrapper:not(.verify-identity__agree--policy--checkbox-wrapper-disabled):hover .verify-identity__agree--policy--checkbox-checked:not(.verify-identity__agree--policy--checkbox-disabled) .verify-identity__agree--policy--checkbox-inner {
    background-color: $white-color;
    border: 2px solid $deep-blue;
  }
}