@import "/src/assets/styles/mixins.scss";

.header-form-view {
  color: $deep-blue;
  width: 100%;

  h3 {
    font-weight: 700;
    text-transform: uppercase;

    @include breakpoint-max($normalDesktop) {
      margin-top: 10px;
      margin-bottom: 6px;
      font-size: 20px;
    }
  }
}

.label-header {
  font-size: 12px;
  font-weight: 600;
  color: $deep-blue;
  @include breakpoint-max($normalDesktop) {
    font-size: 10px;
  }
}