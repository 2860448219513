@import 'src/assets/styles/mixins';

.confirm-details {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 30px 0 75px 0;
  padding-left: 155px;

  @include breakpoint-min-max($tablet, $normalDesktop) {
    padding-top: 10px;
    padding-left: 0;
  }

  @include breakpoint-max($smallDesktop - 1px) {
    justify-content: center;
  }

  @include breakpoint-max($tablet) {
    padding: 20px 5% 30px 5%;
    justify-content: center;
    align-items: flex-start;
  }

  &__container {
    width: 465px;

    @include breakpoint-max($tablet) {
      width: 100%;
    }
  }
}

.confirm-details__form {
  margin-top: 38px;

  @include breakpoint-max($normalDesktop) {
    margin-top: 0;
  }

  &--group {
    width: 100%;

    &:not(:first-child) {
      margin-top: 10px;

      @include breakpoint-max($normalDesktop) {
        margin-top: 6px;
      }
    }
  }

  &--label {
    font-size: 12px;
    font-weight: 500;
    color: $nevada-color;
    margin-bottom: 5px;
  }

  &--input--group {
    position: relative;
    width: 100%;
  }

  &--input--group--date {
    position: relative;
    width: 100%;
    background-color: $gallery-color;
    padding: 10px 20px;
    border-radius: 6px;
  }

  &--input {
    padding: 10px 20px;
    font-size: 12px;
    font-weight: 400;
    color: $black-color;
    background-color: $gallery-color;
    width: 100%;
    border: none;
    border-radius: 6px;

    &:focus {
      outline: none;
    }
  }

  &--input--date {
    @extend .confirm-details__form--input;
    padding: 0;
  }

  &--input--ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding-right: 15%;
  }

  &--btn--update {
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translate(0, -50%);
    font-size: 9px;
    font-weight: 700;
    color: $stone-gray;
    text-decoration: underline;
    cursor: pointer;
  }

  &--btn--submit {
    margin-top: 35px;
    background-color: $limeade-color ;

    @include breakpoint-max($normalDesktop) {
      margin-top: 20px;
    }

    @include breakpoint-max($tablet) {
      margin-top: 30px;
    }
  }
}