// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

@import "/src/assets/styles/mixins.scss";
@import "/src/assets/styles/variables.scss";

* {
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
}

html {
    @include breakpoint-max($tablet) {
        @include hiddenScrollbar;
    }
}

body {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    margin: 0 auto !important;
    display: flex;
    flex-direction: column;
}

.ant-input {
    background-color: $light-gray;
    border-color: $slate-blue-gray;
    border-radius: 6px;

    &:hover {
        border-color: $slate-blue-gray;
    }
}

.ant-btn {
    box-shadow: none;
}

.m-0 {
    margin: 0;
}

.p-0 {
    padding: 0;
}

.mt-auto {
    margin-top: auto;
}

.registration-back-button {
    background: transparent;
    background-color: transparent;
    border: 0;

    span {
        color: $deep-blue;
        text-decoration: underline;
    }
}

.ant-btn.registration-submit-button {
    background-color: $deep-blue;
    color: white;
    border-color: $deep-blue;
    display: flex;
    align-items: center;
    padding: 15px;
    border-radius: 50px;
    font-weight: bold;
    min-height: 40px;
    margin: 0 auto;
    min-width: 245px;
    justify-content: center;

    &:hover,
    &:focus {
        background-color: $deep-blue !important;
        border-color: $deep-blue;
        color: $light-gray;
    }
}

input {
    &:focus {
        outline: none;
    }
}