@import "/src/assets/styles/mixins.scss";

.currency-total-card {
  background-color: #ffffffdb;
  width: 60%;
  padding: 30px;
  border-radius: 30px;
  margin: auto;

  @include breakpoint-max($tablet) {
    width: 90%;
  }

  .currency-total-card-image {
    margin-bottom: 40px;
  }

  .currency-total-card-body {
    display: flex;
    flex-wrap: wrap;

    .currency-total-card-lists {
      width: 100%;

      .currency-total-card-item {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .emoji-code {
          display: flex;
          align-items: center;
          font-size: 18px;
          font-weight: 700;
          color: $deep-blue;
          column-gap: 5px;

          .emoji {
            font-size: 30px;
          }
        }

        .amount {
          font-size: 18px;
          font-weight: 700;
          color: $deep-blue;
        }

      }
    }

    .currency-total-card-total {
      display: flex;
      width: 100%;
      justify-content: space-between;
      color: $deep-blue;
      font-weight: 700;
      font-size: 18px;

      &__label {
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 8px;
        font-size: 20px;
        font-weight: 700;
        color: $deep-blue;

        .emoji {
          font-size: 30px;
          line-height: 1;
        }
      }
    }
  }
}

.debit-card-fee {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 6px;

  &__title {
    text-align: left;
    font-size: 20px;
    font-weight: 600;
    color: $deep-blue;
  }

  &__subtitle {
    font-size: 9px;
    font-weight: 600;
    color: $deep-blue
  }

  &__amount {
    @extend .debit-card-fee__title;
  }
}
.currency-total-card__divide{
  width: 100%;
  height: 1px;
  background-color: $silver-cloud;
  margin: 24px 0;
}