@import '/src/assets/styles/variables';

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 20px;
  padding-top: 21px;
  padding-bottom: 27px;
  border-top: 1px solid $gray-gallery;

  &__text {
    text-align: center;
    font-size: 8.5px;
    font-weight: 500;
    line-height: 2;
    color: $gray-color;
    margin-bottom: 0;
  }

  &__text--link {
    @extend .footer__text;
  }

  &__logo--list {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 14px;
  }

  &__logo--item {}

  &__logo--item--img {
    width: 53px;
    height: 22px;
  }

  &__logo--item--last {
    width: auto;
    height: 22px;
  }

  &__logo--item--img--visa {
    width: 48px;
    height: 16px;
  }
}

.footer {
  &__last--screen {
    background-color: $gray-mine-shaft;
    .footer__text {
      color: $white-color;
    }
  }

}