@import "/src/assets/styles/mixins.scss";

.about-you {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 30px 0 75px 0;
  padding-left: 155px;

  @include breakpoint-min-max($tablet, $normalDesktop) {
    padding-left: 0;
    padding-top: 10px;
  }


  @include breakpoint-max($smallDesktop - 1px) {
    justify-content: center;
  }

  @include breakpoint-max($tablet) {
    padding: 20px 5% 30px 5%;
    justify-content: center;
    align-items: flex-start;
  }

  &__container {
    width: 450px;

    @include breakpoint-max($tablet) {
      width: 100%;
    }
  }
}

.about-you__form {
  margin-top: 30px;

  @include breakpoint-max($normalDesktop) {
    margin-top: 10px;
  }

  &--group {
    position: relative;

    &.fit-content {
      width: fit-content;
    }

    &:not(:first-child) {
      margin-top: 20px;

      @include breakpoint-max($normalDesktop) {
        margin-top: 10px;
      }
    }
  }

  &--label {
    font-size: 12.52px;
    font-weight: 600;
    color: #696d77;
    letter-spacing: 1.5px;
    margin-bottom: 8px;
    display: block;

    @include breakpoint-max($normalDesktop) {
      margin-bottom: 4px;
    }
  }

  &--select {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
  }

  &--option {
    padding: 12px 20px;
    background-color: $light-gray;
    border-radius: 5px;
    font-size: 12.66px;
    font-family: 400;
    color: $black-color;
    cursor: pointer;
  }

  &--option--active {
    background-color: $sky-frost;
    font-weight: 600;
    color: $deep-blue;
  }

  &--date {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 14px;
  }

  &--date--day {
    padding: 12px 19px;
    border: 0.7px solid $slate-blue-gray;
    border-radius: 7px;
    background-color: $light-gray;
    font-size: 12.66px;
    font-weight: 400;
    color: $black-color;
    cursor: pointer;
    max-width: 100px;
    min-width: 65px;
    text-align: center;

    @include hiddenArrowInputNumber;

    &::placeholder {
      color: rgba($color: #000000, $alpha: 0.3);
    }

    &:focus {
      outline: none;
    }
  }

  &--date--month {
    @extend .about-you__form--date--day;
    text-align: center;
    background-image: url("../../assets/images/down-arrow-icon.svg");
    background-size: 12px;
    background-repeat: no-repeat;
    background-position-x: 90%;
    background-position-y: 50%;
    width: 134px;
    max-width: 134px;
    min-height: 45px;
    border: 0.7px solid $slate-blue-gray;

    // * Hidden arrow on select
    -moz-appearance: none;
    /* Firefox */
    -webkit-appearance: none;
    /* Safari and Chrome */
    appearance: none;

    &.gray-color {
      color: rgba($color: #000000, $alpha: 0.3);
    }

    .option.classes:first-child {
      display: none;
    }

    .option.classes {
      color: $black-color;
    }
  }

  &--date--year {
    @extend .about-you__form--date--day;
    width: 74px;
    max-width: 100%;
  }

  &--label--phone {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &--helper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 5px;
  }

  &--helper--img {
    width: 17px;
    height: 17px;
    object-fit: cover;
  }

  &--helper--text {
    font-size: 9px;
    font-weight: 700;
    color: $deep-blue;
    margin-bottom: 0;
  }

  &--input {
    padding: 12px 20px;
    font-size: 14px;
    font-weight: 400;
    color: $black-color;
    background-color: $light-gray;
    width: 100%;
    border: 0.7px solid $slate-blue-gray;
    border-radius: 6px;
    // border-left: solid 0.7px $slate-blue-gray;
    outline: none;

    @include breakpoint-max($normalDesktop) {
      font-size: 12px;
    }

    &:focus {
      outline: none;
    }
  }

  &--input--group {
    position: relative;
  }

  &--input--icon {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translate(0, -50%);
    width: 14px;
    height: 14px;
  }

  &--group-address {
    position: relative;
  }

  &--error {
    position: absolute;
    right: 0;
    bottom: -20px;
    text-align: end;
    margin-top: 4px;
    margin-bottom: 0;

    @include errorMessage;

    &--end {
      text-align: end;
    }
  }

  & &--btn--submit {
    margin-top: 30px;

    @include breakpoint-max($tablet) {
      margin-top: 30px;
    }
  }
}

.about-you__address--list {
  position: absolute;
  top: 47px;
  left: 0;
  background-color: $white-color;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 156px;
  overflow: auto;
  border-radius: 5px;
  padding: 10px 0;

  &--empty {
    overflow: visible;
    height: fit-content;

  }
}

.about-you__address--item {
  padding: 10px 20px;
  font-size: 12.66px;
  font-weight: 500;
  color: $black-color;
  cursor: pointer;

  &:hover {
    background-color: rgba($color: $silver-color, $alpha: 0.38)
  }
}

.about-you__address--empty {
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  color: $silver-color;
  margin: 0 auto;
}

.about-you__form--group-address {

  & .about-you__form--select-address__control {
    font-size: 14px;
    font-weight: 400;
    color: $black-color;
    background-color: $light-gray;
    border: 0.7px solid $slate-blue-gray;
    border-radius: 6px;
    width: 100%;
    padding: 0;

    @include breakpoint-max($normalDesktop) {
      font-size: 12px;
    }

    &:focus {
      outline: none;
    }
  }

  & .about-you__form--select-address__control--is-focused {
    border-color: $slate-blue-gray !important;
    box-shadow: none;
  }

  & .about-you__form--select-address__value-container {
    padding: 12px 20px;
    width: 100px;
  }

  & .about-you__form--select-address__input-container {
    padding: 0;
    margin: 0;
  }

  & .about-you__form--select-address__indicators {
    display: none;
  }

  & .about-you__form--select-address__option {
    cursor: pointer;
  }

  & .about-you__form--select-address__option--is-focused {
    background-color: rgba($color: $silver-color, $alpha: 0.38)u;
  }

  & .about-you__form--select-address__input {
    opacity: 1 !important;
    width: 180%;
  }

  & .about-you__form--select-address__menu-notice--no-options {
    display: none;
  }

  & .about-you__form--select-address__menu-list {
    min-height: 55px;
  }

  & .about-you__form--select--menu {
    &.hidden {
      display: none;
    }
  }
}

.about-you__country {
  &--list {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    height: 156px;
    overflow: auto;
    background-color: $white-color;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    z-index: 2;
    padding: 14px 0;
    @include hiddenScrollbar;
  }

  &--list--empty {
    height: fit-content;
    padding: 24px;
  }

  &--item {
    padding: 14px 16px;
    cursor: pointer;

    &:hover {
      background-color: rgba($color: $silver-color, $alpha: 0.38);
    }
  }

  &--empty {
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    color: $silver-color;
  }
}