@import '/src/assets/styles/mixins';

.driver-license {
  width: 100%;
  margin-top: 12px;
  height: fit-content;
  border: 1px solid $silver-cloud;
  border-radius: 13px 13px 16px 16px;
  transition: all 0.25s linear;
}

.driver-license__form {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 25px;
  padding: 24px 18px;

  @include breakpoint-max(1919px) {
    flex-wrap: wrap;
  }

  &--group {
    position: relative;
  }


  &--label {
    font-size: 12px;
    font-weight: 500;
    color: $nevada-color;
    letter-spacing: 1.5px;
    margin-bottom: 6px;
    white-space: nowrap;
  }

  &--input {
    padding: 12px 20px;
    border-radius: 6px;
    border: 0.625px solid $slate-blue-gray;
    font-size: 12px;
    font-weight: 400;
    color: $black-color;
    background-color: $light-gray;
    height: 40px;

    &:focus {
      outline: none;
    }
  }

  &--input--error {
    border-color: $salmon-red;
  }

  &--input--number {
    width: 144px;
    @include hiddenArrowInputNumber()
  }

  &--input--date {
    text-transform: uppercase;
    width: 144px;
    @include hiddenCalendarInput();
  }

  &--select-arrow {
    width: 10px;
    height: 6px;
    object-fit: cover;
  }

  &--error {
    font-size: 8px;
    font-weight: 500;
    color: $salmon-red;
    margin-bottom: 0;
    text-align: end;
    position: absolute;
    bottom: -16px;
    right: 0;
  }
}

.driver-license__form--group {
  & .driver-license__form--input--states-outlined:not(.driver-license__form--input--states-customize-input) .driver-license__form--input--states-selector {
    padding: 12px 20px;
    border-radius: 6px;
    border: 0.625px solid $slate-blue-gray;
    background-color: $light-gray;
  }

  & .driver-license__form--input--states-outlined:not(.driver-license__form--input--states-disabled):not(.driver-license__form--input--states-customize-input):not(.ant-pagination-size-changer):hover .driver-license__form--input--states-selector {
    border-color: $slate-blue-gray;
  }

  & .driver-license__form--input--states-single {
    height: 40px;
  }

  & .driver-license__form--input--states-selection-item {
    font-size: 12px;
    font-weight: 400;
    color: $black-color;
  }

  .driver-license__form--input--states-focused:where(.css-dev-only-do-not-override-1kuana8).driver-license__form--input--states-outlined:not(.driver-license__form--input--states-disabled):not(.driver-license__form--input--states-customize-input):not(.ant-pagination-size-changer) .driver-license__form--input--states-selector {
    box-shadow: none;
    border-color: $slate-blue-gray;
  }
}